import { createRouter, createWebHistory } from "vue-router";
import FormList from "../FormList.vue";
import FormBuilder from "../FormBuilder.vue";
import FormEditor from "../FormEditor.vue";
import FormPreview from "../FormPreview.vue";
import LoginView from "../LoginView.vue";

const routes = [
    { path: "/", component: FormList, meta: { requiresAuth: true } },
    {
        path: "/preview",
        name: "FormPreview",
        component: FormPreview,
        props: (route) => ({
            formData: route.query.formData
                ? JSON.parse(route.query.formData)
                : null
        }),
        meta: { requiresAuth: true }
    },
    {
        path: "/builder",
        name: "builder",
        component: FormBuilder,
        meta: { requiresAuth: true },
        props: (route) => ({ formId: route.query.formId })
    },
    {
        path: "/editor/:id",
        component: FormEditor,
        props: true,
        meta: { requiresAuth: true }
    },
    { path: "/login", component: LoginView }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!localStorage.getItem("user")) {
            next({ path: "/login" });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
