<template>
  <div>
    <!-- only show navbar if logged in -->
    <nav
      v-if="loggedIn"
      class="navbar navbar-expand-lg navbar-dark mb-5 container-fluid"
      style="background: #0e0e0e"
    >
      <div class="container">
        <img
          src="@/assets/CircleLogoWhite.png"
          width="25"
          class="me-2"
          alt="Logo"
        />
        <!-- Navbar brand with router link -->
        <router-link to="/" class="navbar-brand"> Form Builder </router-link>

        <!-- Toggle button for mobile navigation -->
        <button
          class="navbar-toggler text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse text-bg-white" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Form List</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/builder" class="nav-link">
                Form Builder
              </router-link>
            </li>
            <li class="nav-item">
              <button class="nav-link" @click="signOut">
                Sign Out
                <i class="bi bi-box-arrow-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
export default {
    name: "Forkto PDF Builder",
    data() {
        return {
            loggedIn: false
        };
    },
    methods: {
        signOut() {
            signOut(getAuth())
                .then(() => {
                    localStorage.removeItem("user");
                    this.loggedIn = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    mounted() {
        onAuthStateChanged(getAuth(), (user) => {
            if (!user) {
                this.$router.push("/login");
            } else {
                this.loggedIn = true;
            }
        });
    }
};
</script>
