<template>
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center min-vh-100">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="text-center mb-4">
              <img
                src="/TextLogoBlack.png"
                alt="Forkto Logo"
                class="img-fluid"
                style="max-width: 60%"
              />
            </div>
            <button
              @click="signInWithGoogle"
              class="btn btn-lg btn-primary w-100 mb-3"
            >
              <i class="bi bi-google me-2"></i>Sign in with Google
            </button>
            <p class="text-muted text-center">
              &copy; {{ new Date().getFullYear() }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { collection, getDoc, doc, setDoc } from 'firebase/firestore';
import db from './firebase/init';
const router = useRouter();
const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(getAuth(), provider)
      .then(async (result) => {
        const user = result.user;
        localStorage.setItem('user', JSON.stringify(user));

        const collectionRef = collection(db, 'users');
        const docRef = await getDoc(doc(collectionRef, user.uid));
        if (!docRef.exists()) {
          await setDoc(doc(collectionRef, user.uid), {
            email: user.email,
            name: user.displayName,
            photoURL: user.photoURL,
          });
        }

        router.push('/');
      })
      .catch((error) => {
        console.log(error);
      });
}
</script>
<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
</style>
