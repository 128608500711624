<template>
    <div class="container my-5">
        <div class="row">
            <div class="col bg-light p-1 p-lg-4">
                <div class="mb-3">
                    <div
                        class="d-flex justify-content-between align-items-center mb-4"
                    >
                        <h2>Form Data</h2>
                        <button class="btn btn-success" @click="saveForm">
                            Save Form
                        </button>
                    </div>

                    <div class="mb-3">
                        <div class="form-check">
                            <input
                                v-model="formData.includeHeader"
                                class="form-check-input"
                                type="checkbox"
                                id="includeHeader"
                            />
                            <label class="form-check-label" for="includeHeader"
                                >Include Header</label
                            >
                        </div>
                    </div>
                    <label for="formTitle" class="form-label"
                        >Form Title:</label
                    >
                    <input
                        v-model="formData.title"
                        type="text"
                        class="form-control"
                        id="formTitle"
                        placeholder="Enter form title"
                    />
                </div>

                <div class="mb-3">
                    <label for="formDescription" class="form-label"
                        >Form Description:</label
                    >
                    <textarea
                        v-model="formData.description"
                        class="form-control"
                        id="formDescription"
                        rows="3"
                        placeholder="Enter form description"
                    ></textarea>
                </div>

                <div>
                    <h2 class="mb-4">Form Sections</h2>
                    <div class="mb-3">
                        <label for="sectionType" class="form-label"
                            >Section Type:</label
                        >
                        <select
                            v-model="selectedSection"
                            class="form-select"
                            id="sectionType"
                        >
                            <option value="">Select a section type</option>
                            <option value="yes-no">Yes/No Questions</option>
                            <option value="table">Table with Empty Rows</option>
                            <option value="sign-off">Sign Off</option>
                            <option value="qr-code">QR Code</option>
                        </select>
                        <button
                            class="btn btn-primary mt-2"
                            @click="addSection"
                        >
                            Add Section
                        </button>
                    </div>
                    <div
                        v-for="(section, index) in formData.sections"
                        :key="index"
                        class="mb-4 p-4 bg-light rounded shadow-sm"
                    >
                        <div class="mb-3">
                            <label
                                :for="'sectionTitle-' + index"
                                class="form-label"
                                >Section Title:</label
                            >
                            <input
                                v-model="section.title"
                                type="text"
                                class="form-control"
                                :id="'sectionTitle-' + index"
                                placeholder="Enter section title"
                            />
                        </div>
                        <div v-if="section.type === 'yes-no'" class="mb-3">
                            <label
                                :for="'sectionQuestions-' + index"
                                class="form-label"
                                >Questions:</label
                            >
                            <textarea
                                :value="section.questions.join('\n')"
                                @input="handleQuestionInput($event, index)"
                                class="form-control"
                                :id="'sectionQuestions-' + index"
                                rows="3"
                                placeholder="Enter questions, one per line"
                            ></textarea>
                        </div>
                        <div v-if="section.type === 'table'" class="mb-3">
                            <label
                                :for="'tableHeaders-' + index"
                                class="form-label"
                                >Table Headers:</label
                            >
                            <textarea
                                :value="formatHeadersForInput(section.headers)"
                                @input="handleHeadersInput($event, index)"
                                class="form-control"
                                :id="'tableHeaders-' + index"
                                rows="2"
                                placeholder="Enter column headers, one per line"
                            ></textarea>
                            <label
                                :for="'emptyRows-' + index"
                                class="form-label"
                                >Number of Empty Rows:</label
                            >
                            <input
                                v-model.number="section.numEmptyRows"
                                type="number"
                                class="form-control"
                                :id="'emptyRows-' + index"
                                placeholder="Enter the number of empty rows"
                            />
                        </div>
                        <div v-if="section.type === 'qr-code'" class="mb-3">
                            <label
                                :for="'qrCodeUrl-' + index"
                                class="form-label"
                                >URL:</label
                            >
                            <input
                                v-model="section.url"
                                type="text"
                                class="form-control"
                                :id="'qrCodeUrl-' + index"
                                placeholder="Enter the URL"
                            />
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                <button
                                    class="btn btn-secondary me-2"
                                    @click="moveSection(index, -1)"
                                    :disabled="index === 0"
                                >
                                    <i class="bi bi-arrow-up"></i>
                                </button>
                                <button
                                    class="btn btn-secondary"
                                    @click="moveSection(index, 1)"
                                    :disabled="
                                        index === formData.sections.length - 1
                                    "
                                >
                                    <i class="bi bi-arrow-down"></i>
                                </button>
                            </div>
                            <button
                                class="btn btn-danger"
                                @click="removeSection(index)"
                            >
                                Remove Section
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <form-preview :form-data="formData"></form-preview>
            </div>
        </div>
    </div>
</template>

<script>
import FormPreview from "./FormPreview.vue";
import router from "./router/router";
// import { getDoc, collection, doc } from "firebase/firestore";
import db from "./firebase/init";
import { collection, addDoc, getDoc, doc, setDoc } from "firebase/firestore";

export default {
    components: {
        FormPreview
    },
    props: {
        formId: {
            type: String,
            default: null
        }
    },
    mounted() {
        if (this.formId) {
            this.fetchFormData();
        }
    },
    data() {
        return {
            formData: {
                title: "",
                description: "",
                includeHeader: true,
                sections: []
            },
            selectedSection: ""
        };
    },
    methods: {
        async fetchFormData() {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const docRef = doc(db, "users", user.uid, "forms", this.formId);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    this.formData = docSnapshot.data();
                } else {
                    console.error("Form not found");
                    // Handle error or redirect to form list
                }
            } catch (error) {
                console.error("Error fetching form data:", error);
                // Handle error
            }
        },
        moveSection(index, direction) {
            const sections = this.formData.sections;
            const newIndex = index + direction;

            if (newIndex >= 0 && newIndex < sections.length) {
                const temp = sections[index];
                sections[index] = sections[newIndex];
                sections[newIndex] = temp;
            }
        },
        formatHeadersForInput(headers) {
            return headers.join("\n");
        },
        formatRowsForInput(rows) {
            return rows.map((row) => row.join(", ")).join("\n");
        },
        handleHeadersInput(event, index) {
            const headers = event.target.value.split("\n");
            this.formData.sections[index].headers = headers;
        },
        handleRowsInput(event, index) {
            // Handling rows similarly, ensuring each cell in the row is defined
            const rows = event.target.value.split("\n").map((row) =>
                row
                    .split(",")
                    .map((cell) => cell.trim())
                    .filter((cell) => cell)
            );
            this.formData.sections[index].rows = rows;
        },
        handleQuestionInput(event, index) {
            const questions = event.target.value.split("\n");
            this.formData.sections[index].questions = questions;
        },
        addSection() {
            if (this.selectedSection === "yes-no") {
                this.formData.sections.push({
                    type: "yes-no",
                    title: "",
                    questions: []
                });
            } else if (this.selectedSection === "table") {
                this.formData.sections.push({
                    type: "table",
                    title: "",
                    headers: [],
                    rows: []
                });
            } else if (this.selectedSection === "sign-off") {
                this.formData.sections.push({
                    type: "sign-off",
                    title: "Sign Off"
                });
            } else if (this.selectedSection === "qr-code") {
                this.formData.sections.push({
                    type: "qr-code",
                    title: "QR Code",
                    url: ""
                });
            }
        },
        removeSection(index) {
            this.formData.sections.splice(index, 1);
        },
        async saveForm() {
            console.log("Form data:", this.formData);
            try {
                const formData = {
                    ...this.formData,
                    lastUpdated: new Date().toISOString(), // Set the lastUpdated field to the current date and time
                    sections: this.formData.sections.map((section) => ({
                        ...section,
                        questions: section.questions || [],
                        headers: section.headers || [],
                        rows: section.rows || []
                    }))
                };

                const user = JSON.parse(localStorage.getItem("user"));

                if (!user) {
                    console.error("User not found. Please sign in.");
                    router.push("/login");
                    return;
                }

                const collectionRef = collection(
                    db,
                    "users",
                    user.uid,
                    "forms"
                );

                if (this.formId) {
                    // Editing an existing form
                    try {
                        await setDoc(doc(collectionRef, this.formId), formData);
                        console.log("Form updated successfully");
                        this.$router.push("/");
                    } catch (error) {
                        console.error("Error updating form:", error);
                        // Handle error
                    }
                } else {
                    // Creating a new form
                    try {
                        const docRef = await addDoc(collectionRef, formData);
                        console.log("Document written with ID: ", docRef.id);
                        this.$router.push("/");
                    } catch (error) {
                        console.error("Error saving new form:", error);
                        // Handle error
                    }
                }
            } catch (error) {
                console.error("Error saving form:", error);
                // Handle error
            }
        }
    }
};
</script>
