// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnRLNzQGpMwF966iGILHvEaANdJ8qDQb8",
  authDomain: "forkto-form-builder.firebaseapp.com",
  projectId: "forkto-form-builder",
  storageBucket: "forkto-form-builder.appspot.com",
  messagingSenderId: "57249226102",
  appId: "1:57249226102:web:228ac9d2ad1fc37225ae57",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore();
export default db;
