<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center bg-danger"
    style="height: 85vh;"
  >
    <embed :src="pdfUrl" type="application/pdf" width="100%" class="h-100" />
  </div>
</template>

<script>
import { generatePDF } from "./pdfGenerator";

export default {
    name: "FormPreview",
    props: {
        formData: {
            type: Object,
            required: true,
            deep: true
        }
    },
    data() {
        return {
            pdfUrl: null
        };
    },
    mounted() {
        this.generatePreview();
    },
    watch: {
        formData: {
            handler() {
                this.generatePreview();
            },
            deep: true
        }
    },
    methods: {
        async generatePreview() {
            const pdf = await generatePDF(this.formData);
            pdf.getDataUrl((url) => {
                this.pdfUrl = url;
            });
        }
    }
};
</script>
