<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h1 class="">My Forms</h1>
            <div class="d-flex gap-2">
                <button @click="newForm" class="btn btn-primary">
                    <i class="bi bi-plus me-2"></i>
                    Create New Form
                </button>
                <button @click="downloadAll" class="btn btn-outline-secondary">
                    <i class="bi bi-download me-2"></i>
                    Download All
                </button>
            </div>
        </div>
        <div class="row">
            <div
                v-for="form in forms"
                :key="form.id"
                class="col-md-6 col-lg-4 mb-4"
            >
                <!-- {{ form }} -->
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title d-flex justify-content-between">
                            {{ form.title }}
                            <div class="btn-group">
                                <button
                                    type="button"
                                    class="btn dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i class="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            @click="editForm(form)"
                                            ><i class="bi bi-pencil me-2"></i
                                            >Edit</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            @click="deleteForm(form)"
                                            ><i class="bi bi-trash me-2"></i
                                            >Delete</a
                                        >
                                    </li>
                                    <!-- list item for generating a preview of the form in jpeg first page -->
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            @click="generatePreview(form)"
                                            ><i class="bi bi-eye me-2"></i
                                            >Generate PNG</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </h5>
                        <p class="card-text">{{ form.description }}</p>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <a
                                href="#"
                                @click="generateDownloadLink(form)"
                                class="btn btn-primary"
                            >
                                <i class="bi bi-download me-2"></i>Download
                            </a>
                            <div>
                                <button
                                    @click="previewForm(form)"
                                    class="btn btn-outline-secondary me-2"
                                >
                                    <i class="bi bi-eye me-1"></i>Preview
                                </button>
                                <!-- Dropdown for more options -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { generatePDF } from "./pdfGenerator";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import db from "./firebase/init";

export default {
    data() {
        return {
            forms: [],
            previewFormData: null
        };
    },
    methods: {
        async generatePreview(form) {
            try {
                // Ensure pdf.js is loaded and set up
                const pdfjsLib = window["pdfjs-dist/build/pdf"];

                // Generate PDF data URL
                const pdfDataUrl = await generatePDF(form);

                // Loading the PDF document
                const pdf = await pdfjsLib.getDocument(pdfDataUrl).promise;

                // Get the first page
                const page = await pdf.getPage(1);
                const scale = 1.5; // Consider adjusting scale based on user preferences or content size
                const viewport = page.getViewport({ scale });

                // Prepare canvas using PDF page dimensions
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                await page.render(renderContext).promise;

                // Output the canvas to image - can download or display in an <img> element
                const imgSrc = canvas.toDataURL("image/png");

                // Example: Download the image
                const a = document.createElement("a");
                a.href = imgSrc;
                a.download = "preview.png";
                a.click();

                // Clean up canvas memory
                canvas.width = 0;
                canvas.height = 0;
            } catch (error) {
                console.error("Failed to generate preview:", error);
                alert(
                    "There was an error generating the preview. Please try again."
                );
            }
        },

        async downloadAll() {
            for (const form of this.forms) {
                await this.generateDownloadLink(form);
            }
        },
        async generateDownloadLink(form) {
            const pdf = await generatePDF(form);
            pdf.getDataUrl((url) => {
                // download the pdf directly
                const link = document.createElement("a");
                link.href = url;
                link.download = `${form.title}.pdf`;
                link.click();
            });
        },
        async fetchForms() {
            try {
                const user = JSON.parse(localStorage.getItem("user"));

                const forms = (
                    await getDocs(collection(db, "users", user.uid, "forms"))
                ).docs.map((doc) => {
                    return { id: doc.id, ...doc.data() };
                });

                this.forms = forms;
            } catch (error) {
                console.error("Error fetching forms:", error);
                // Handle error
            }
        },
        editForm(form) {
            this.$router.push({
                name: "builder",
                query: { formId: form.id }
            });
        },
        previewForm(form) {
            this.$router.push({
                name: "FormPreview",
                query: { formData: JSON.stringify(form) }
            });
        },
        async deleteForm(form) {
            const confirmDelete = window.confirm(
                "Are you sure you want to delete this form?"
            );
            if (confirmDelete) {
                const user = JSON.parse(localStorage.getItem("user"));
                const collectionRef = collection(
                    db,
                    "users",
                    user.uid,
                    "forms"
                );
                await deleteDoc(doc(collectionRef, form.id));
                this.forms = this.forms.filter((f) => f.id !== form.id);
            }
        },
        newForm() {
            this.$router.push("/builder");
        }
    },
    mounted() {
        this.fetchForms();
    }
};
</script>
