// pdfGenerator.js
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function generatePDF(formData) {
    const content = await generatePDFContent(formData);

    const docDefinition = {
        content,
        footer: function (currentPage, pageCount) {
            return {
                marginLeft: 40,
                marginRight: 40,
                columns: [
                    {
                        width: "*",
                        text: "Get the digital traceable version online at FORKTO.COM",
                        style: "footerCta"
                    },
                    {
                        width: 100,
                        text: `Page ${currentPage} of ${pageCount}`,
                        alignment: "right",
                        style: "footerPage"
                    }
                ]
            };
        },
        styles: {
            bigTitle: {
                fontSize: 24,
                bold: true
            },
            body: {
                fontSize: 16
            },
            description: {
                fontSize: 12
            },
            footerCta: {
                fontSize: 12
            },
            footerPage: {
                fontSize: 10,
                italics: true
            }
        },
        pageMargins: [40, 50, 40, 50] // Adjust these values as needed
    };

    return pdfMake.createPdf(docDefinition);
}

async function generatePDFContent(formData) {
    const content = [];

    if (formData.includeHeader) {
        try {
            const formHeaderComponent = await import(
                "./components/FormHeader.vue"
            );

            if (
                formHeaderComponent &&
                formHeaderComponent.default.methods.generatePDFContent
            ) {
                const headerContent =
                    formHeaderComponent.default.methods.generatePDFContent.call(
                        {
                            title: formData.title,
                            description: formData.description
                        }
                    );
                content.push(headerContent);
            }
        } catch (error) {
            console.error("Failed to load FormHeader component:", error);
        }
    }

    for (const section of formData.sections) {
        try {
            const fileName = toPascalCase(section.type) + "Section";
            const sectionComponent = await import(
                `./components/${fileName}.vue`
            );
            if (
                sectionComponent &&
                sectionComponent.default.methods.generatePDFContent
            ) {
                const sectionContent =
                    await sectionComponent.default.methods.generatePDFContent(
                        section
                    );
                console.log("Section Content:", sectionContent);
                content.push(sectionContent);
            }
        } catch (error) {
            console.error(
                "Failed to load component for section:",
                section.type,
                "; Error:",
                error
            );
        }
    }

    return content;
}

function toPascalCase(str) {
    return str
        .match(/[a-z]+/gi)
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        )
        .join("");
}
